<template>
  <div class="mobile-form">
    <form @submit.prevent="mobileVerify">
      <div class="form-group">
        <div v-if="formError" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
        <JhakaasInput inputType="text" :label="$t('mobilenumber')" inputId="userMobileno" reference="mobileno"
          helperText="" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" autocomplete="false" @keydown.space.prevent
          required disabled :value="userMobileno" @onChange="value => userMobileno = value">
          <template v-slot:endIcon>
            <div style="color:white">
              <span :class="localDisplayLang === 'ara' ? 'rtl-change' : 'change'">
                <span class="input-span" @click="changeFrom">{{ $t("change") }}</span>
              </span>
            </div>
          </template>
        </JhakaasInput>
  
        <div class="pin-override">
        <PinLayout 
        value="" 
        pinCount="6" 
        @onChange="updatePin"
        @onCompleted="pinCompleted"
        />
        </div>
      

        <!-- <div class="form-control">
          <div class="label-container">
            <label>{{ $t("enter otp") }}</label>
            <p class="required"></p>
          </div>
          <div id="pininputs" class="pin-inputs" dir="ltr">
            <input
              v-for="(item, index) in 6"
              :key="index"
              class="pin"
              type="text"
              v-model="userPin[item - 1]"
              @input="grabPin"
              maxlength="1"
              autocomplete="off"
              @keydown.space.prevent
            />
          </div>
        </div> -->
        <div class="form-control">
          <span class="mobile-form-notreceived">{{ $t("not recieved otp") }}</span>
          <span class="mobile-form-resend" @click="resendCaptcha">{{ $t("resend") }}</span>
          <span class="mobile-form-timer" v-show="showTimer">(00:{{ counter }})</span>
        </div>
      </div>
      <div>
        <p class="otp_error_text">{{ $t("otp text") }}</p>
      </div>
      <div class="login-button">
        <button class="button-primary signup"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']" type="submit">
          {{ $t("LOGIN") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { _providerId } from "@/provider-config.js";
import { mapGetters } from "vuex";
import { store } from "@/store/store";

export default {
  props: ["input", "formData","disabled"],
  data() {
    return {
      userMobileno: "",
      userPin: [],
      profilePin: "",
      formError: null,
      showTimer: false,
      counter: 59,
      providerUniqueId: _providerId,
      localDisplayLang: null,
    };
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },

  computed: {
    ...mapGetters(["country", "appConfig"]),
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("login-response", (data) => {
      if (!data.errorcode) {
        console.log("THE LOGIN DATA --- >", data);
        localStorage.setItem("sessionToken", data.success);

        // this.$emit("closePopup");
        eventBus.$emit("subscriberDetails");
      }
    })

    eventBus.$on("resend-response", (response) => {
      if (response.reason) {
        this.formError = response.reason;
      } else {
        if (!this.showTimer) {
          this.showTimer = true;
          let timer = setInterval(() => {
            this.counter--;
            if (this.counter < 0) {
              clearInterval(timer);
              this.counter = 59;
              this.showTimer = false;
            }
          }, 1000);
        }
      }
    });

    eventBus.$on("mobileverify-response", (response) => {
      if (response.reason) {
        this.formError = response.reason;
      } else {
        let payload = {
          type: "mobileVerify",
          params: {
            devicetype: "PC",
            mobileno: this.userMobileno,
            otp: this.profilePin,
            country: this.country.CountryCode,
          },
        };

        console.log("THE BLOCK REACHED HERE ---- > ", payload);

        eventBus.$emit("subscriberLogin", payload);
      }
    });
  },
  mounted() {

    if (this.formData.isResend && !this.appConfig.featureEnabled.isCaptchaEnabled) {
      this.resend();
    }

    this.setupForm();
    this.setupPin();

    console.log("THIS IS FROM DATA --- > ", this.formData);

    // Send the OTP upon page start
    // this.resend();

    eventBus.$on("auth-close", () => {
      this.$emit("closePopup");
    });

    eventBus.$on("login-response", (data) => {
      if (!data.errorcode) {
        console.log("THE LOGIN DATA --- >", data);
        localStorage.setItem("sessionToken", data.success);

        // this.$emit("closePopup");
        eventBus.$emit("subscriberDetails");
      }
    })

    eventBus.$on("resend-response", (response) => {
      if (response.reason) {
        this.formError = response.reason;
      } else {
        if (!this.showTimer) {
          this.showTimer = true;
          let timer = setInterval(() => {
            this.counter--;
            if (this.counter < 0) {
              clearInterval(timer);
              this.counter = 59;
              this.showTimer = false;
            }
          }, 1000);
        }
      }
    });

    eventBus.$on("mobileverify-response", (response) => {
      if (response.reason) {
        this.formError = response.reason;
      } else {
        let payload = {
          type: "mobileVerify",
          params: {
            devicetype: "PC",
            mobileno: this.userMobileno,
            otp: this.profilePin,
            country: this.country.CountryCode,
          },
        };

        console.log("THE BLOCK REACHED HERE ---- > ", payload);

        eventBus.$emit("subscriberLogin", payload);
      }
    });
  },
  methods: {
    setupForm() {
      this.userMobileno = this.input;
      let input = document.getElementById("userMobileno");
      input.style.backgroundColor = "#5C5C5D";
      input.style.opacity = "0.3";
    },
    pinCompleted(pin) {
    this.profilePin = pin
    this.mobileVerify()

   },
    updatePin(value) {
    this.userPin = value.split('') 
    },
    changeFrom() {
      let info = {
        formType: "lookup",
      };
      this.$emit("change", info);
    },

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },

    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },

    resendCaptcha() {

      if (this.appConfig.featureEnabled.isCaptchaEnabled) {

        let template = {
          input: this.inputtype === 'Email' ? "email" : 'mobile',
          formType: "verifyResendCaptcha",
          mobileno: this.userMobileno ? this.userMobileno : ""
        };

        this.$emit("change", template);
      } else {
        this.resend();
      }

    },

    resend() {
      let payload = {
        mobileno: this.input,
      };

      // if(this.appConfig.featureEnabled.isCaptchaEnabled) {
      //   payload.captcha = this.formData.captcha
      // }

      eventBus.$emit("resend-link", payload);
    },

    mobileVerify() {
      if (!this.verifyOtp(this.profilePin)) return;
      let payload = {
        mobileno: this.userMobileno,
      };

      if (this.profilePin) {
        payload.otp = this.profilePin;
      }
      this.mobileVerifyNew(payload)

    },
    mobileVerifyNew(payload) {
      eventBus.$emit("loader", true);
      store
        .dispatch("subscriberVerify", payload)
        .then((response) => {
          eventBus.$emit("loader", false);
          if (response.data) {
            console.log("mobile verified", response.data);
            if (response.data.reason) {
              this.formError = response.data.reason;
            } else {
              let payload = {
                type: "mobileVerify",
                params: {
                  devicetype: "PC",
                  mobileno: this.userMobileno,
                  otp: this.profilePin,
                  country: this.country.CountryCode,
                },
              };

              eventBus.$emit("subscriberLogin", payload);
            }

          } else if (response.reason) {
            this.formError = response.reason;
          }
        }).catch((error) => console.log(error));
    },
    verifyOtp(pin) {
      this.formError = null;
      if (!pin) {
        this.formError = this.$t("Please Enter OTP");
        return false;
      }
      return true;
    },
  },

  beforeDestroy() {
    // eventBus.$off("mobileverify-response")
  },
  components: {
    PinLayout: () => import("@/components/utils/PinLayout.vue"),
    JhakaasInput: () => import("@/components/utils/JhakaasInput.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss"  scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./mobileVerification.scss"
</style>
